export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";

export const GET_DESIGNERS = "GET_DESIGNERS";

export const USER_LOADING = "USER_LOADING";

export const GET_ERRORS = "GET_ERRORS";

export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
