import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentAdmin, logoutAdmin } from "./actions/authActions";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import ListUsers from "./components/user/ListUsers";
import ListUsersByDesigner from "./components/user/ListUsersByDesigner";
import Landing from "./components/layout/Landing";
import User from "./components/user/User";
import UsersNaikCetak from "./components/user/UsersNaikCetak";
import FPS from "./components/user/FPS";

import Login from "./components/auth/Login";

import "./App.css";

if (localStorage.jwtTokenAdmin) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtTokenAdmin);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtTokenAdmin);
  // Set user and isAdminAuthenticated
  store.dispatch(setCurrentAdmin(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutAdmin());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Route exact path="/" component={Landing} />
            <Route exact path="/all/:id" component={ListUsers} />
            <Route exact path="/list/:id" component={ListUsersByDesigner} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/users/:id" component={User} />
            <Route exact path="/naik-cetak/:id" component={UsersNaikCetak} />
            <Route exact path="/fps/:id" component={FPS} />
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
