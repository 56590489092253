import { combineReducers } from "redux";

import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import designerReducer from "./designerReducer";

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  user: userReducer,
  designer: designerReducer
});
