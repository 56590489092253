import { GET_DESIGNERS } from "../actions/types";

const initialState = {
  designers: [],
  designer: {},
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case USER_LOADING:
    //   return {
    //     ...state,
    //     loading: action.payload
    //   };
    case GET_DESIGNERS:
      return {
        ...state,
        designers: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
