import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUser } from "../../actions/userActions";
import moment from "moment";
import axios from "axios";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  returnSentuhanAkhir = sentuhanAkhir => {
    let paket;
    if (sentuhanAkhir.includes("Paket Best Offer")) {
      paket = "Best Offer";
    } else if (sentuhanAkhir.includes("Paket Complete")) {
      paket = "Complete";
    } else if (sentuhanAkhir.includes("Paket Value")) {
      paket = "Value";
    } else {
      paket = "-";
    }

    return paket;
  };

  returnSpesifikasiAmplop = sentuhanAkhir => {
    let amplop = [];
    if (
      sentuhanAkhir.includes("Paket Best Offer") ||
      sentuhanAkhir.includes("Paket Complete") ||
      sentuhanAkhir.includes("Paket Value") ||
      sentuhanAkhir.includes("Cetak Emas (Gold Foil)")
    )
      amplop.push("Foil");

    if (
      sentuhanAkhir.includes("Paket Best Offer") ||
      sentuhanAkhir.includes("Gold Seal Emblem")
    )
      amplop.push("Segel Emas");

    if (sentuhanAkhir.includes("Cetak Timbul")) amplop.push("Emboss");

    if (amplop.length > 0) {
      return amplop.map(e => `${e}, `);
    }
  };

  returnSpesifikasiIsi = sentuhanAkhir => {
    let isi = [];
    if (
      sentuhanAkhir.includes("Paket Best Offer") ||
      sentuhanAkhir.includes("Paket Complete") ||
      sentuhanAkhir.includes("Paket Value") ||
      sentuhanAkhir.includes("Cetak Emas (Gold Foil)")
    )
      isi.push("Foil");

    if (sentuhanAkhir.includes("Cetak Timbul")) isi.push("Emboss");

    if (isi.length > 0) {
      return isi.map(e => `${e}, `);
    }
  };

  returnProdukTambahan = sentuhanAkhir => {
    let produkTambahan = [];
    if (sentuhanAkhir.includes("Paket Best Offer")) {
      produkTambahan.push("Label");
      produkTambahan.push("Buku Tamu");
      produkTambahan.push("Bridesmaid Card");
    }

    if (sentuhanAkhir.includes("Paket Complete")) produkTambahan.push("Label");

    if (sentuhanAkhir.includes("Wax Seal")) produkTambahan.push("Wax Seal");

    if (produkTambahan.length > 0) {
      return produkTambahan.map(e => <li>{e}</li>);
    }
  };

  render() {
    const { user } = this.props;

    let now = new Date();
    let tanggalProduksi = now.setDate(now.getDate() + 2);
    tanggalProduksi = moment(tanggalProduksi).format("DD MMMM YYYY");

    let tanggalKirim = now.setDate(now.getDate() + 32);
    tanggalKirim = moment(tanggalKirim).format("DD MMMM YYYY");

    console.log(tanggalProduksi, tanggalKirim);
    return (
      <div style={{ margin: 0 }} className="fps">
        <div className="text-center">
          <img
            src={`https://sandbox-api2.pentone.id/assets/img/user/final_design/${user.idOrder.final_design}`}
            alt="image"
            style={{
              width: "auto",
              maxHeight: "75vh"
            }}
          />
        </div>
        <table
          className="border mt-2 table-fps"
          style={{
            maxHeight: "30vh",
            borderWidth: 1,
            borderColor: "black"
          }}
        >
          <thead className="text-center border">
            <tr>
              <td
                className="text-light bg-dark border py-1"
                style={{ width: "5%", fontSize: "14px" }}
              >
                <b>DATA CUSTOMER</b>
              </td>
              <td
                className="py-1"
                style={{ fontSize: "14px", backgroundColor: "#f1f1f1" }}
                colSpan="6"
              >
                <b> SPESIFIKASI UNDANGAN</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border align-top p-1" width="14%">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Nama</b>
                      </td>
                      <td>: </td>
                      <td>{user.name}</td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b> Alamat</b>
                      </td>
                      <td className="align-top">:</td>
                      <td>{user.alamat}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Email</b>
                      </td>
                      <td>:</td>
                      <td>{user.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> No. HP</b>
                      </td>
                      <td>:</td>
                      <td>{user.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="15%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Timeline Produksi</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Tanggal Produksi</b>{" "}
                      </td>
                      <td className="align-top">:</td>
                      <td>{tanggalProduksi}</td>
                    </tr>
                    <tr style={{ height: "5px" }}></tr>
                    <tr>
                      <td className="align-top">
                        <b> Estimasi Pengiriman</b>
                      </td>
                      <td className="align-top">:</td>
                      <td>{tanggalKirim}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="16%">
                <table>
                  <tbody>
                    <tr className="text-center pb-4">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b> Spesifikasi</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="text-capitalize">
                        <b> Kategori Undangan </b>:{" "}
                        {user.idOrder.idTipeUndangan === "softCover"
                          ? "Custom"
                          : user.idOrder.idTipeUndangan}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b> Jumlah</b>
                      </td>
                      <td>:</td>
                      <td>{user.idOrder.quantity}</td>
                    </tr>
                    <tr style={{ height: "3px" }}></tr>
                    <tr className="pt-4">
                      <td>
                        <b>Jenis Kertas</b>{" "}
                      </td>
                      <td className="align-top">:</td>
                      <td>
                        {user.idOrder.idTipeUndangan == "popular"
                          ? `Pentone Matte Paper ${user.idOrder.idJenisKertas}`
                          : user.idOrder.idJenisKertas}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border  align-top p-1" width="14%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b> Ukuran</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b> Amplop</b>
                      </td>
                      <td>:</td>
                      <td>21 x 15 cm</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Isi</b>
                      </td>
                      <td>:</td>
                      <td>20,5 x 14,5 cm</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Kupon</b>
                      </td>
                      <td>:</td>
                      <td>5 x 8 cm</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Peta</b>
                      </td>
                      <td>:</td>
                      <td>9 x 11 cm</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="17%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b> Sentuhan Akhir </b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <b>Paket Sentuhan Akhir</b> :{" "}
                        {this.returnSentuhanAkhir(user.idOrder.sentuhanAkhir)}
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Amplop</b>
                      </td>
                      <td className="align-top">:</td>
                      <td>
                        {this.returnSpesifikasiAmplop(
                          user.idOrder.sentuhanAkhir
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Isi</b>
                      </td>
                      <td>:</td>
                      <td>
                        {this.returnSpesifikasiIsi(user.idOrder.sentuhanAkhir)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top py-1" width="12%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1 mx-0"
                        style={{
                          fontSize: "12px",
                          paddingRight: "0px",
                          paddingLeft: "2px"
                        }}
                      >
                        <b>Produk Tambahan</b>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="pl-4">
                          {this.returnProdukTambahan(
                            user.idOrder.sentuhanAkhir
                          )}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="12%">
                <table className="w-100">
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Catatan Khusus</b>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="pl-3">
                          <li>Label</li>
                          <li>Buku Tamu</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

class FPS extends React.Component {
  constructor() {
    super();
    this.state = {
      final_design: null
    };
  }

  componentDidMount() {
    this.props.getUser(this.props.match.params.id);
    console.log(this.props);
  }

  render() {
    const { user, loading } = this.props.user;
    if (typeof user._id !== "undefined") {
      return (
        <div>
          <ReactToPrint
            trigger={() => <a href="#">Print this out!</a>}
            content={() => this.componentRef}
            pageStyle="@page { size: landscape;}"
          />
          <ComponentToPrint ref={el => (this.componentRef = el)} user={user} />
        </div>
      );
    } else {
      return (
        <div>
          <h1>sek</h1>
        </div>
      );
    }
  }
}

FPS.propTypes = {
  getUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default connect(mapStateToProps, { getUser })(FPS);
