import { GET_USER, GET_USERS, GET_ERRORS, USER_LOADING } from "./types";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// Get Users tanpa designer
export const getUsers = () => dispatch => {
  dispatch(setUserLoading(true));
  axios
    .get(
      `https://sandbox-api2.pentone.id/api/users/admin/all?not_status=Review&designer=null`
    )
    .then(res => {
      console.log(res);
      dispatch({ type: GET_USERS, payload: res.data });
    })
    .catch(err => {
      dispatch(setUserLoading(false));

      console.log(err);
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const getUsersByDesigner = idDesigner => dispatch => {
  dispatch(setUserLoading(true));
  axios
    .get(
      `https://sandbox-api2.pentone.id/api/users/admin/all?not_status=Naik Cetak&designer=${idDesigner}`
    )
    .then(res => {
      console.log(res);
      dispatch({ type: GET_USERS, payload: res.data });
    })
    .catch(err => {
      dispatch(setUserLoading(false));

      console.log(err);
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const getUsersNaikCetak = () => dispatch => {
  dispatch(setUserLoading(true));
  axios
    .get(
      `https://sandbox-api2.pentone.id/api/users/admin/all?status=Naik Cetak`
    )
    .then(res => {
      console.log(res);
      dispatch({ type: GET_USERS, payload: res.data });
    })
    .catch(err => {
      dispatch(setUserLoading(false));

      console.log(err);
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

// Get User by id
export const getUser = id => dispatch => {
  dispatch(setUserLoading(true));
  axios
    .get(`https://sandbox-api2.pentone.id/api/users/admin/user/${id}`)
    .then(res => {
      console.log(res);
      dispatch({ type: GET_USER, payload: res.data });
    })

    .catch(err => {
      dispatch(setUserLoading(false));

      console.log(err);
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};

export const updateStatusUser = undanganData => {
  axios
    .post(
      `https://sandbox-api2.pentone.id/api/users/editOrder/${undanganData.idOrder}`,
      undanganData
    )
    .then(res => {
      console.log("test suc");
      console.log(res.data);
      toast.success("Perubahan telah disimpan!");
    })
    .catch(err => {
      console.log("test err");
      console.log(err.response);
      toast.error("Terjadi error. Perubahan tidak disimpan.");
    });
};

export const assignDesigner = newData => {
  axios
    .post(`https://sandbox-api2.pentone.id/api/orders/assign-designer`, newData)
    .then(res => {
      // console.log(res.data);
      toast.success("Perubahan telah disimpan!");
    })
    .catch(err => {
      // console.log(err.response);
      toast.error("Terjadi error. Perubahan tidak disimpan.");
    });
};

export const setUserLoading = loading => {
  return {
    type: USER_LOADING,
    payload: loading
  };
};
