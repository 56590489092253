import React, { Component } from "react";
import { getDesigners } from "../../actions/designerActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import userIcon from "../../img/user-icon.png";

class Landing extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getDesigners();
  }

  render() {
    const { designers } = this.props.designer;

    return (
      <div className="landing">
        <div className="dark-overlay landing-inner text-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Siapa kamu?</h1>
                <div className="row mt-5">
                  {designers.map(designer => {
                    return (
                      <Link
                        to={`/all/${designer._id}`}
                        key={designer._id}
                        className="col"
                      >
                        <img
                          src={userIcon}
                          alt=""
                          className="w-25"
                          style={{ borderRadius: "15px" }}
                        />
                        <h4 className="text-white">{designer.name}</h4>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Landing.propTypes = {
  getDesigners: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  designer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  designer: state.designer
});

export default connect(mapStateToProps, { getDesigners })(Landing);
