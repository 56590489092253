import { GET_DESIGNERS, GET_ERRORS } from "./types";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

//Get Designers
export const getDesigners = () => dispatch => {
  axios
    .get("https://sandbox-api2.pentone.id/api/designers")
    .then(res => dispatch({ type: GET_DESIGNERS, payload: res.data }))
    .catch(err => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
