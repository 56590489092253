import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUser, updateStatusUser } from "../../actions/userActions";
import { withRouter, Link } from "react-router-dom";
import ReactDOM from "react-dom";
import moment from "moment";
import isEmpty from "../../utils/is-empty";
import ReactToPrint from "react-to-print";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import * as axios from "axios";

class User extends Component {
  constructor() {
    super();
    this.state = {
      catatan_khusus: [],
      additionalItems: [],

      tambah_catatan_khusus: "",
      loading: false,
      loading_additional: false,
      final_design: null
    };
  }

  componentDidMount() {
    this.props.getUser(this.props.match.params.id);
    console.log(this.props.user.user);
    axios
      .get(
        `https://sandbox-api2.pentone.id/api/logs?id_user=${this.props.match.params.id}&category=catatan_khusus`
      )
      .then(res => {
        console.log(res.data);
        this.setState({ catatan_khusus: res.data });
        axios
          .get(
            `https://sandbox-api2.pentone.id/api/additionalItems?idOrder=${res.data.idOrder._id}`
          )
          .then(res2 => {
            console.log(res2.data);
          })
          .catch(err2 => {
            console.error(err2);
          });
      })
      .catch(err => console.log(err));
    this.setState({ loading_additional: true });

    axios
      .get(
        `https://sandbox-api2.pentone.id/api/users/admin/user/${this.props.match.params.id}`
      )
      .then(res => {
        axios
          .get(
            `https://sandbox-api2.pentone.id/api/additionalItems?idOrder=${res.data.idOrder._id}`
          )
          .then(res2 => {
            this.setState({ additionalItems: res2.data });
            this.setState({ loading_additional: false });
          })
          .catch(err2 => {
            console.error(err2);
            this.setState({ loading_additional: false });
          });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading_additional: false });
      });
  }

  onClickStatus = (status, e) => {
    // window.alert(`Status: ${status}`);
    const newData = {};
    newData.idUser = this.props.user.user._id;
    newData.idOrder = this.props.user.user.idOrder._id;
    // if (status == "First Payment") {
    //   newData.tanggalDP = Date.now();id
    // }
    newData.status = status;

    let deliveryDate = new Date();
    deliveryDate.setDate(deliveryDate.getDate() + 30);
    // console.log(deliveryDate);
    if (status == "Naik Cetak") {
      newData.shipping_date = deliveryDate;
    }

    updateStatusUser(newData);
    this.props.getUser(this.props.match.params.id);
    setTimeout(function() {
      document.getElementById("checkboxStatus").checked = false;
    }, 1500);
    if (status == "Naik Cetak") {
      const { history } = this.props;

      setTimeout(function() {
        // this.context.history.push(`/`);
        history.push("/");
      }, 3000);
    }
  };

  renderStatusTemplate(deadline, status) {
    let warna, category, sisa, modal;
    if (deadline >= 10) {
      if (deadline > 13) {
        warna = "lime";
      } else if (deadline == 13) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      if (status == "First Payment") {
        sisa = deadline - 10;
        category = "Preview Design";
      } else if (status == "Preview Design") {
        sisa = deadline;
        category = "Naik Cetak";
        warna = "lime";
      }
    } else {
      if (deadline > 6) {
        warna = "lime";
      } else if (deadline > 4) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      sisa = deadline - 0;
      category = "Naik Cetak";
    }
    return (
      <span
        className="btn font-weight-bold shadow align-top float-right p-1 px-5 ml-3 mb-0"
        style={{ backgroundColor: warna }}
      >
        {sisa} hari lagi {category}
      </span>
    );
  }

  renderStatusCustom(deadlineCustom, status) {
    let warna, sisa, category;
    if (deadlineCustom >= 24) {
      sisa = deadlineCustom - 24;
      if (sisa >= 6) {
        warna = "lime";
      } else if (sisa >= 4) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      if (status == "First Payment") {
        category = "Preview Design";
      } else if (status == "Preview Design") {
        warna = "lime";
        sisa = sisa + 14;
        category = "Design Selesai";
      } else {
        sisa = sisa + 14 + 10;
        warna = "lime";
        category = "Naik Cetak";
      }
    } else if (deadlineCustom >= 10) {
      sisa = deadlineCustom - 17;
      if (sisa >= 11) {
        warna = "lime";
      } else if (sisa >= 6) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      if (status == "Design Selesai") {
        sisa = sisa + 10;
        warna = "lime";
        category = "Naik Cetak";
      } else {
        category = "Design Selesai";
      }
    } else {
      sisa = deadlineCustom - 21;
      if (sisa >= 7) {
        warna = "lime";
      } else if (sisa >= 5) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      category = "Naik Cetak";
    }
    return (
      <span
        className="btn font-weight-bold shadow align-top float-right p-1 px-5 ml-3 mb-0"
        style={{ backgroundColor: warna }}
      >
        {sisa} hari lagi {category}
      </span>
    );
  }

  tambahCatatanKhusus = e => {
    this.setState({ loading: true });
    e.preventDefault();
    this.setState({ tambah_catatan_khusus: "" });
    let newCatatanKhusus = {
      id_user: this.props.user.user._id,
      category: "catatan_khusus",
      content: this.state.tambah_catatan_khusus
    };
    console.log(newCatatanKhusus);
    axios
      .post(`https://sandbox-api2.pentone.id/api/logs`, newCatatanKhusus)
      .then(res => {
        axios
          .get(
            `https://sandbox-api2.pentone.id/api/logs?id_user=${this.props.match.params.id}&category=catatan_khusus`
          )
          .then(res =>
            this.setState({ catatan_khusus: res.data, loading: false })
          )
          .catch(err => console.log(err));
      });
  };

  onCentang = e => {
    let id = e.currentTarget.getAttribute("id");
    let checked = e.currentTarget.checked;
    console.log(checked);
    axios
      .post(`https://sandbox-api2.pentone.id/api/logs/${id}`, {
        resolved: checked,
        resolved_at: Date.now()
      })
      .then(res => {
        axios
          .get(
            `https://sandbox-api2.pentone.id/api/logs?id_user=${this.props.match.params.id}&category=catatan_khusus`
          )
          .then(res =>
            this.setState({ catatan_khusus: res.data, loading: false })
          )
          .catch(err => console.log(err));
      });
  };

  onUploadFinalDesign = async e => {
    e.preventDefault();

    let uploadFinalDesign = new FormData();
    uploadFinalDesign.append("final_design", this.state.final_design);
    try {
      this.setState({ loading: true });
      let response = await axios.post(
        `https://sandbox-api2.pentone.id/api/orders/updateFinalDesign/${this.props.user.user.idOrder._id}`,
        uploadFinalDesign
      );
      console.log(response.data);
      toast.success("Final Design Uploaded");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
      toast.error("Terjadi Error!");
    }
  };

  print = () => {
    window.print();
  };

  // Create Document Component

  render() {
    const { user, loading } = this.props.user;
    // console.log(this.state);

    if (typeof user._id !== "undefined") {
      let now = new Date();
      now = moment(now).format("DD/MM/YYYY");

      let deadline = new Date(user.idOrder.tanggalDP);
      deadline = moment(deadline).format("DD/MM/YYYY");

      let a = moment(now, "DD/MM/YYYY");
      let b = moment(deadline, "DD/MM/YYYY");
      let diffDays = b.diff(a, "days");

      let deadlineTemplate = diffDays + 15;
      let deadlineCustom = diffDays + 31;

      return (
        <div>
          <div className="m-3 mx-4 dashboard">
            <div className="card shadow p-3 mb-2">
              <h4 className="mb-0">
                {user.name}

                {/* {this.onAdditionalItems(user.idOrder._id)} */}
                {/* <span>
                  <small>
                    <a
                      href=""
                      className="d-inline"
                      data-toggle="modal"
                      data-target=".bd-example-modal-sm"
                    >
                      Ganti status
                    </a>
                  </small>
                </span> */}
                {user.idOrder.design_type == "template"
                  ? this.renderStatusTemplate(
                      deadlineTemplate,
                      user.idOrder.status
                    )
                  : this.renderStatusCustom(
                      deadlineCustom,
                      user.idOrder.status
                    )}
              </h4>
              <div className="text-right">
                {user.idOrder.status != "Naik Cetak" ? (
                  <div>
                    <label
                      className="form-check-label pr-1"
                      htmlFor="checkboxStatus"
                    >
                      {user.idOrder.design_type == "template"
                        ? user.idOrder.status == "First Payment"
                          ? "Preview design telah selesai"
                          : "Telah masuk proses cetak"
                        : user.idOrder.status != "Design Selesai"
                        ? user.idOrder.status == "First Payment"
                          ? "Preview design telah selesai"
                          : "Design fix telah selesai"
                        : "Telah masuk proses cetak"}{" "}
                      <input
                        className="text-right form-check-input-lg ml-3"
                        style={{ transform: "scale(1.5)" }}
                        type="checkbox"
                        value=""
                        id="checkboxStatus"
                        onClick={
                          user.idOrder.design_type == "template"
                            ? user.idOrder.status == "First Payment"
                              ? this.onClickStatus.bind(this, "Preview Design")
                              : this.onClickStatus.bind(this, "Naik Cetak")
                            : user.idOrder.status != "Design Selesai"
                            ? user.idOrder.status == "First Payment"
                              ? this.onClickStatus.bind(this, "Preview Design")
                              : this.onClickStatus.bind(this, "Design Selesai")
                            : this.onClickStatus.bind(this, "Naik Cetak")
                        }
                      ></input>
                    </label>
                  </div>
                ) : (
                  <div className="pr-5">
                    sudah naik cetak
                    <input
                      className="text-right form-check-input-lg ml-3 d-none"
                      type="checkbox"
                      id="checkboxStatus"
                    ></input>
                  </div>
                )}
              </div>
            </div>
            <div className="row ">
              <div className="col-3 pr-0">
                <div className="card shadow px-3 py-2">
                  <h5 className="mb-0">Data Customer</h5>
                  <hr className="float-left w-100 m-0" />
                  <p>
                    <b>Nama:</b> {user.name}
                  </p>
                  <p>
                    <b>Alamat:</b> {user.alamat}
                  </p>
                  <p>
                    <b>Email:</b> {user.email}
                  </p>
                  <p>
                    <b>Phone:</b> {user.phone}
                  </p>
                </div>
                <div className="card shadow px-3 py-2 mt-2">
                  <h5 className="mb-0">Detail Undangan</h5>
                  <hr className="float-left w-100 m-0" />
                  {user.idOrder.design_type == "template" ? (
                    <div>
                      <img
                        src={`https://sandbox-api2.pentone.id/assets/img/products/${user.idOrder.idProduct.slug}-undangan.jpg`}
                        alt=""
                        className="w-50"
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto"
                        }}
                      />
                      <p>
                        <b>Desain:</b> {user.idOrder.idProduct.name}
                      </p>
                      <p>
                        <b>Quantity:</b> {user.idOrder.quantity}
                      </p>
                      <p>
                        <b>Kategori:</b> {user.idOrder.idTipeUndangan}
                      </p>
                      <p>
                        <b>Jenis Amplop:</b> {user.idOrder.jenisAmplop}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        <b>Quantity:</b> {user.idOrder.quantity}
                      </p>
                      <p>
                        <b>Ukuran:</b> {user.idOrder.ukuran}
                      </p>
                      <p>
                        <b>Tema Desain:</b> {user.idOrder.design_theme}
                      </p>
                      <p>
                        <b>Orientasi:</b> {user.idOrder.orientation}
                      </p>
                      <p>
                        <b>Kupon:</b> {user.idOrder.kupon.join(", ")}
                      </p>
                    </div>
                  )}
                  <p>
                    <b>Jenis Kertas:</b> {user.idOrder.idJenisKertas}
                  </p>
                  <p>
                    <b>Add Ons:</b>{" "}
                    {user.idOrder.sentuhanAkhir.map(addOn => {
                      return (
                        <li className="ml-3" key={addOn}>
                          {addOn}
                        </li>
                      );
                    })}
                  </p>
                  <p>
                    <b>Gaya Penulisan:</b>
                    <a
                      href={`https://sandbox-api2.pentone.id/assets/img/user/wordings/${user.idOrder.idGayaPenulisan}`}
                      target="_blank"
                    >
                      Klik Disini
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-5 px-2">
                <div className="card shadow px-3 py-2">
                  <div className="row">
                    <div className="col-6 mb-2">
                      <h5 className="mb-0">Data Mempelai Pria</h5>
                      <hr className="float-left w-100 m-0" />
                      <p>
                        Nama Lengkap:{" "}
                        {user.idOrder.dataMempelaiPria.namaLengkap}
                      </p>
                      <p>
                        Nama Panggilan:{" "}
                        {user.idOrder.dataMempelaiPria.namaPanggilan}
                      </p>
                      <p>Nama Ayah: {user.idOrder.dataMempelaiPria.namaAyah}</p>
                      <p>Nama Ibu: {user.idOrder.dataMempelaiPria.namaIbu}</p>
                      <h5 className="mb-0 mt-3">Data Akad</h5>
                      <hr className="float-left w-100 m-0" />
                      <p>
                        Tanggal Akad:{" "}
                        {moment(user.idOrder.dataAkad.tanggal).format(
                          "DD MMMM YYYY"
                        )}
                      </p>
                      <p>Lokasi: {user.idOrder.dataAkad.lokasi}</p>
                      <p>Waktu Mulai: {user.idOrder.dataAkad.waktuMulai}</p>
                      <p>Waktu Selesai: {user.idOrder.dataAkad.waktuSelesai}</p>
                    </div>
                    <div className="col-6">
                      <h5 className="mb-0">Data Mempelai Wanita</h5>
                      <hr className="float-left w-100 m-0" />
                      <p>
                        Nama Lengkap:{" "}
                        {user.idOrder.dataMempelaiWanita.namaLengkap}
                      </p>
                      <p>
                        Nama Panggilan:{" "}
                        {user.idOrder.dataMempelaiWanita.namaPanggilan}
                      </p>
                      <p>
                        Nama Ayah: {user.idOrder.dataMempelaiWanita.namaAyah}
                      </p>
                      <p>Nama Ibu: {user.idOrder.dataMempelaiWanita.namaIbu}</p>
                      <h5 className="mb-0 mt-3">Data Resepsi</h5>
                      <hr className="float-left w-100 m-0" />
                      <p>
                        Tanggal Resepsi:{" "}
                        {moment(user.idOrder.dataResepsi.tanggal).format(
                          "DD MMMM YYYY"
                        )}
                      </p>
                      <p>Lokasi: {user.idOrder.dataResepsi.lokasi}</p>
                      <p>Waktu Mulai: {user.idOrder.dataResepsi.waktuMulai}</p>
                      <p>
                        Waktu Selesai: {user.idOrder.dataResepsi.waktuSelesai}
                      </p>
                    </div>
                  </div>
                </div>
                {user.idOrder.design_type == "custom" ? (
                  <div className="card shadow px-3 py-2 mt-2">
                    <h5 className="mb-0">Konsep Undangan:</h5>
                    <p>{user.idOrder.detail_konsep}</p>
                    <h5 className="mb-0 mt-2">Referensi Desain:</h5>
                    <div>
                      {user.idOrder.referensi_design.map(ref => {
                        let split = ref.split(".");
                        let ekstensi = split[split.length - 1];
                        if (ekstensi === "jpg" || ekstensi === "png") {
                          return (
                            <a
                              href={`https://sandbox-api2.pentone.id/assets/img/user/customs/${ref}`}
                              target="_blank"
                            >
                              <img
                                src={`https://sandbox-api2.pentone.id/assets/img/user/customs/${ref}`}
                                alt=""
                                className="w-25 m-2"
                              />
                            </a>
                          );
                        } else {
                          return (
                            <a
                              href={`https://sandbox-api2.pentone.id/assets/img/user/customs/${ref}`}
                              target="_blank"
                              style={{ fontSize: "100px" }}
                              className="align-middle"
                            >
                              <i className="fas fa-file-word"></i>
                            </a>
                          );
                        }
                      })}
                    </div>
                  </div>
                ) : (
                  " "
                )}
                <div className="card shadow my-2">
                  <div className="card-body">
                    <h5>Final Design</h5>
                    <div className="row">
                      <div className="col-6">
                        {isEmpty(this.props.user.user.idOrder.final_design) ? (
                          <p>Belum Upload Final Design </p>
                        ) : (
                          <img
                            className="img-fluid"
                            src={`https://sandbox-api2.pentone.id/assets/img/user/final_design/${this.props.user.user.idOrder.final_design}`}
                          />
                        )}
                      </div>
                      <div className="col-6">
                        <form onSubmit={this.onUploadFinalDesign.bind(this)}>
                          <div className="form-group">
                            <label htmlFor="final_design">
                              Upload Final Design
                            </label>
                            <input
                              type="file"
                              name="final_design"
                              id="final_design"
                              className="form-control"
                              onChange={e =>
                                this.setState({
                                  final_design: e.target.files[0]
                                })
                              }
                              required
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-block btn-dark float-right shadow w-50"
                          >
                            Upload
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 pl-0">
                <div className="card shadow px-3 py-2">
                  <h5 className="mb-0">Catatan khusus</h5>
                  <hr className="float-left w-100 m-0" />
                  {this.state.catatan_khusus.map(ck => (
                    <div className="form-check mb-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        checked={ck.resolved}
                        id={ck._id}
                        onClick={e => this.onCentang(e)}
                      />
                      <label className="form-check-label" htmlFor={ck._id}>
                        {ck.content}
                      </label>
                    </div>
                  ))}
                  {/* <hr className="float-left w-100 m-0" /> */}
                  <form onSubmit={e => this.tambahCatatanKhusus(e)}>
                    <div className="form-group mt-4 mb-1">
                      {/* <label htmlFor="tambah_catatan_khusus">
                        Tambah Catatan Khusus
                      </label> */}
                      <textarea
                        type="text"
                        className="form-control mb-0"
                        value={this.state.tambah_catatan_khusus}
                        onChange={e =>
                          this.setState({
                            tambah_catatan_khusus: e.target.value
                          })
                        }
                        placeholder="Tambah catatan khusus.."
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-block btn-dark float-right shadow w-25"
                    >
                      {this.state.loading ? "Loading" : "Tambah"}
                    </button>
                  </form>
                </div>
                {/* <div className="mt-3">
                  <Link
                    to={`/fps/${user._id}`}
                    className="btn btn-block btn-dark float-right shadow w-50"
                  >
                    <h5>Lihat FPS</h5>
                  </Link>
                </div> */}
                <div className="mt-3">
                  {console.log(this.state.additionalItems)}
                  {!this.state.loading_additional ? (
                    (console.log(this.state.additionalItems),
                    (
                      <div>
                        <ReactToPrint
                          trigger={() => (
                            <a
                              href="#"
                              className="btn btn-block btn-dark float-right shadow w-50"
                              // onClick={this.renderAdditionalItems()}
                            >
                              Cetak FPS
                            </a>
                          )}
                          content={() => this.componentRef}
                          pageStyle="@page { size: landscape;}"
                        />
                        <div className="d-none">
                          <ComponentToPrint
                            ref={el => (this.componentRef = el)}
                            user={user}
                            catatanKhusus={this.state.catatan_khusus}
                            additionalItems={this.state.additionalItems}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <a
                      href="#"
                      className="btn btn-block btn-dark float-right shadow w-50 disabled"
                      // onClick={this.renderAdditionalItems()}
                    >
                      Loading..
                    </a>
                  )}{" "}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade bd-example-modal-sm"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="mySmallModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content p-4">
                <h3>Ganti Status:</h3>
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-dark"
                      onClick={this.onClickStatus.bind(this, "First Payment")}
                      data-dismiss="modal"
                    >
                      First Payment
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-dark"
                      onClick={this.onClickStatus.bind(this, "Preview Design")}
                      data-dismiss="modal"
                    >
                      Preview Design
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-dark"
                      onClick={this.onClickStatus.bind(this, "Design Selesai")}
                      data-dismiss="modal"
                    >
                      Design Selesai
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-dark"
                      onClick={this.onClickStatus.bind(this, "Naik Cetak")}
                      data-dismiss="modal"
                    >
                      Naik Cetak
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover={false}
          />
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

User.propTypes = {
  getUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default connect(mapStateToProps, { getUser })(User);

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  returnSentuhanAkhir = sentuhanAkhir => {
    let paket;
    if (sentuhanAkhir.includes("Paket Best Offer")) {
      paket = "Best Offer";
    } else if (sentuhanAkhir.includes("Paket Complete")) {
      paket = "Complete";
    } else if (sentuhanAkhir.includes("Paket Value")) {
      paket = "Value";
    } else {
      paket = "-";
    }

    return paket;
  };

  returnSpesifikasiAmplop = sentuhanAkhir => {
    let amplop = [];
    if (
      sentuhanAkhir.includes("Paket Best Offer") ||
      sentuhanAkhir.includes("Paket Complete") ||
      sentuhanAkhir.includes("Paket Value") ||
      sentuhanAkhir.includes("Cetak Emas (Gold Foil)")
    )
      amplop.push("Foil");

    if (
      sentuhanAkhir.includes("Paket Best Offer") ||
      sentuhanAkhir.includes("Gold Seal Emblem")
    )
      amplop.push("Segel Emas");

    if (sentuhanAkhir.includes("Cetak Timbul")) amplop.push("Emboss");

    if (amplop.length > 0) {
      return amplop.map(e => `${e}, `);
    }
  };

  returnSpesifikasiIsi = sentuhanAkhir => {
    let isi = [];
    if (
      sentuhanAkhir.includes("Paket Best Offer") ||
      sentuhanAkhir.includes("Paket Complete") ||
      sentuhanAkhir.includes("Paket Value") ||
      sentuhanAkhir.includes("Cetak Emas (Gold Foil)")
    )
      isi.push("Foil");

    if (sentuhanAkhir.includes("Cetak Timbul")) isi.push("Emboss");

    if (isi.length > 0) {
      return isi.map(e => `${e}, `);
    }
  };

  returnProdukTambahan = sentuhanAkhir => {
    let produkTambahan = [];
    if (sentuhanAkhir.includes("Paket Best Offer")) {
      produkTambahan.push("Label");
      produkTambahan.push("Buku Tamu");
      produkTambahan.push("Bridesmaid Card");
    }

    if (sentuhanAkhir.includes("Paket Complete")) produkTambahan.push("Label");

    if (sentuhanAkhir.includes("Wax Seal")) produkTambahan.push("Wax Seal");

    if (produkTambahan.length > 0) {
      return produkTambahan.map(e => <li>{e}</li>);
    }
  };

  render() {
    const { user } = this.props;
    const { catatanKhusus } = this.props;

    // this.setState({ additionalItems: this.props.additionalItems });

    let now = new Date();
    let tanggalProduksi = now.setDate(now.getDate() + 2);
    tanggalProduksi = moment(tanggalProduksi).format("DD MMMM YYYY");

    let tanggalKirim = now.setDate(now.getDate() + 39);
    tanggalKirim = moment(tanggalKirim).format("DD MMMM YYYY");

    console.log(tanggalProduksi, tanggalKirim);
    return (
      <div style={{ margin: 0 }} className="fps">
        <div className="text-center">
          <img
            src={`https://sandbox-api2.pentone.id/assets/img/user/final_design/${user.idOrder.final_design}`}
            alt="image"
            style={{
              width: "auto",
              maxHeight: "75vh"
            }}
          />
        </div>
        <table
          className="border mt-2 table-fps"
          style={{
            maxHeight: "30vh",
            borderWidth: 1,
            borderColor: "black"
          }}
        >
          <thead className="text-center border">
            <tr>
              <td
                className="text-light bg-dark border py-1"
                style={{ width: "5%", fontSize: "14px" }}
              >
                <b>DATA CUSTOMER</b>
              </td>
              <td
                className="py-1"
                style={{ fontSize: "14px", backgroundColor: "#f1f1f1" }}
                colSpan="6"
              >
                <b> SPESIFIKASI UNDANGAN</b>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border align-top p-1" width="14%">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Nama</b>
                      </td>
                      <td>: </td>
                      <td>{user.name}</td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b> Alamat</b>
                      </td>
                      <td className="align-top">:</td>
                      <td>{user.alamat}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Email</b>
                      </td>
                      <td>:</td>
                      <td>{user.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b> No. HP</b>
                      </td>
                      <td>:</td>
                      <td>{user.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="15%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Timeline Produksi</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Tanggal Produksi</b>{" "}
                      </td>
                      <td className="align-top">:</td>
                      <td>{tanggalProduksi}</td>
                    </tr>
                    <tr style={{ height: "5px" }}></tr>
                    <tr>
                      <td className="align-top">
                        <b> Estimasi Pengiriman</b>
                      </td>
                      <td className="align-top">:</td>
                      <td>{tanggalKirim}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="16%">
                <table>
                  <tbody>
                    <tr className="text-center pb-4">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b> Spesifikasi</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="text-capitalize">
                        <b> Kategori Undangan </b>:{" "}
                        {user.idOrder.idTipeUndangan === "softCover"
                          ? "Custom"
                          : user.idOrder.idTipeUndangan}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b> Jumlah</b>
                      </td>
                      <td>:</td>
                      <td>{user.idOrder.quantity}</td>
                    </tr>
                    <tr style={{ height: "3px" }}></tr>
                    <tr className="pt-4">
                      <td>
                        <b>Jenis Kertas</b>{" "}
                      </td>
                      <td className="align-top">:</td>
                      <td>
                        {user.idOrder.idTipeUndangan == "popular"
                          ? `Pentone Matte Paper ${user.idOrder.idJenisKertas}`
                          : user.idOrder.idTipeUndangan == "premium"
                          ? `Hardcover ${user.idOrder.idJenisKertas}`
                          : user.idOrder.idJenisKertas}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border  align-top p-1" width="14%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b> Ukuran</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b> Amplop</b>
                      </td>
                      <td>:</td>
                      <td>
                        {user.idOrder.ukuran === "24x18cm"
                          ? "24 x 18 cm"
                          : "21 x 15 cm"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Isi</b>
                      </td>
                      <td>:</td>
                      <td>
                        {user.idOrder.ukuran === "24x18cm"
                          ? "23.5 x 17.5 cm"
                          : "20.5 x 14.5 cm"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b> Kupon</b>
                      </td>
                      <td>:</td>
                      <td>5 x 8 cm</td>
                    </tr>
                    <tr>
                      <td>
                        <b> Peta</b>
                      </td>
                      <td>:</td>
                      <td>9 x 11 cm</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="17%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        colSpan="3"
                        style={{ fontSize: "12px" }}
                      >
                        <b> Sentuhan Akhir </b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <b>Paket Sentuhan Akhir</b> :{" "}
                        {this.returnSentuhanAkhir(user.idOrder.sentuhanAkhir)}
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top">
                        <b>Amplop</b>
                      </td>
                      <td className="align-top">:</td>
                      <td>
                        {this.returnSpesifikasiAmplop(
                          user.idOrder.sentuhanAkhir
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Isi</b>
                      </td>
                      <td>:</td>
                      <td>
                        {this.returnSpesifikasiIsi(user.idOrder.sentuhanAkhir)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top py-1" width="12%">
                <table>
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1 mx-0"
                        style={{
                          fontSize: "12px",
                          paddingRight: "0px",
                          paddingLeft: "2px"
                        }}
                      >
                        <b>Produk Tambahan</b>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="pl-4">
                          {this.returnProdukTambahan(
                            user.idOrder.sentuhanAkhir
                          )}
                          {this.props.additionalItems.length > 0
                            ? this.props.additionalItems.map(_id => (
                                <li>{_id.name}</li>
                              ))
                            : ""}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border align-top p-1" width="12%">
                <table className="w-100">
                  <tbody>
                    <tr className="text-center">
                      <td
                        className="border-bottom py-1"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Catatan Khusus</b>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul className="pl-3">
                          {catatanKhusus.map(ck => (
                            <li id={ck._id}>{ck.content}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
