import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logoutAdmin } from "../../actions/authActions";

class Navbar extends Component {
  onLogOut = () => {
    this.props.logoutAdmin();
    window.location = "/login";
  };

  render() {
    return (
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark mb-4">
        <div className="">
          <h4 className="navbar-brand">Dashboard Pentone</h4>
          <button
            className="nav-link btn btn-primary float-right"
            onClick={this.onLogOut}
          >
            Logout
          </button>
        </div>
      </nav>
    );
  }
}
Navbar.propTypes = {
  logoutAdmin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutAdmin })(Navbar);
