import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUsersNaikCetak, updateStatusUser } from "../../actions/userActions";
import Moment from "react-moment";

class UsersNaikCetak extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getUsersNaikCetak();
  }

  onClickStatus = (name, idUser, idOrder, status, e) => {
    // window.alert(`Status: ${status}`);
    const newData = {};
    newData.idUser = idUser;
    newData.idOrder = idOrder;
    newData.status = status;

    var answer = window.confirm(`Order atas nama ${name} telah selesai?`);
    if (answer) {
      updateStatusUser(newData);
      // console.log(newData, name);
      this.props.getUsersNaikCetak();
    } else {
      // console.log("gajadi diambil");
    }
    // console.log(newData);
    updateStatusUser(newData);
  };

  render() {
    const { users, loading } = this.props.user;
    let no = 1;

    if (loading) {
      return (
        <div className="container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a disabled className="nav-link text-primary">
                Daftar Order Baru
              </a>
            </li>
            <li className="nav-item">
              <a disabled className="nav-link text-primary">
                Order Kamu
              </a>
            </li>
            <li className="nav-item">
              <a disabled className="nav-link active">
                Dalam Proses Cetak
              </a>
            </li>
          </ul>
          <div className="text-center">
            <h4 style={{ margin: "auto", display: "block" }}>
              Harap tunggu sebentar..
            </h4>
          </div>
        </div>
      );
    } else {
      // console.log(users);
      return (
        <div className="container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <Link
                to={`/all/${this.props.match.params.id}`}
                className="nav-link "
              >
                Daftar Order Baru
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/list/${this.props.match.params.id}`}
                className="nav-link "
              >
                Order Kamu
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/naik-cetak/${this.props.match.params.id}`}
                className="nav-link active"
              >
                Dalam Proses Cetak
              </Link>
            </li>
          </ul>
          <div className="card pb-3 mt-3 shadow">
            <table className="table">
              <tbody>
                <tr className="bg-secondary text-white">
                  <th>No</th>
                  <th>Nama Customer</th>
                  <th>Delivery Date</th>
                  <th></th>
                  {/* <th></th> */}
                  {/* <th>Status</th> */}
                </tr>
                {users.map(user => {
                  return (
                    <tr key={user._id}>
                      <td className="text-center">{(no, no++)}</td>
                      <td>{user.name}</td>
                      <td>
                        <Moment format="DD MMMM YYYY">
                          {user.idOrder.shipping_date}
                        </Moment>
                      </td>
                      <td>
                        <Link
                          to={`/users/${user._id}`}
                          className="btn btn-outline-dark shadow py-0"
                        >
                          Lihat Pesanan
                        </Link>
                      </td>
                      {/* <td>
                        <button
                          to={`/users/${user._id}`}
                          className="btn btn-outline-dark shadow py-0"
                          onClick={this.onClickStatus.bind(
                            this,
                            user.name,
                            user._id,
                            user.idOrder._id,
                            "Order Selesai"
                          )}
                        >
                          Hapus Pesanan
                        </button>
                      </td> */}
                      {/* <td>{user.idOrder.status}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
}

UsersNaikCetak.propTypes = {
  getUsersNaikCetak: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default connect(mapStateToProps, { getUsersNaikCetak })(UsersNaikCetak);
