import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { getUsersByDesigner } from "../../actions/userActions";

class ListUsersByDesigner extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    if (!this.props.auth.isAdminAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.getUsersByDesigner(this.props.match.params.id);
    console.log(this.props.match.params.id);
  }

  renderStatusTemplate(deadline, status) {
    let warna, category, sisa;
    if (deadline >= 10) {
      if (deadline > 13) {
        warna = "lime";
      } else if (deadline == 13) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      if (status == "First Payment") {
        sisa = deadline - 10;
        category = "Preview Design";
      } else if (status == "Preview Design") {
        sisa = deadline;
        category = "Naik Cetak";
        warna = "lime";
      }
    } else {
      if (deadline > 6) {
        warna = "lime";
      } else if (deadline > 4) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      sisa = deadline - 0;
      category = "Naik Cetak";
    }
    return (
      <div>
        <p
          onClick={this.openModal}
          className="btn p-0 px-3 mb-0 font-weight-bold"
          style={{ backgroundColor: warna }}
        >
          {sisa} hari lagi {category}
        </p>
      </div>
    );
  }

  renderStatusCustom(deadlineCustom, status) {
    let warna, sisa, category;
    if (deadlineCustom >= 24) {
      sisa = deadlineCustom - 24;
      if (sisa >= 6) {
        warna = "lime";
      } else if (sisa >= 4) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      if (status == "First Payment") {
        category = "Preview Design";
      } else if (status == "Preview Design") {
        warna = "lime";
        sisa = sisa + 14;
        category = "Design Selesai";
      } else {
        sisa = sisa + 14 + 10;
        warna = "lime";
        category = "Naik Cetak";
      }
    } else if (deadlineCustom >= 10) {
      sisa = deadlineCustom - 17;
      if (sisa >= 11) {
        warna = "lime";
      } else if (sisa >= 6) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      if (status == "Design Selesai") {
        sisa = sisa + 10;
        warna = "lime";
        category = "Naik Cetak";
      } else {
        category = "Design Selesai";
      }
    } else {
      sisa = deadlineCustom - 21;
      if (sisa >= 7) {
        warna = "lime";
      } else if (sisa >= 5) {
        warna = "yellow";
      } else {
        warna = "red";
      }
      category = "Naik Cetak";
    }
    return (
      <div>
        <p
          onClick={this.openModal}
          className="btn p-0 px-3 mb-0 font-weight-bold"
          style={{ backgroundColor: warna }}
        >
          {sisa} hari lagi {category}
        </p>
      </div>
    );
  }

  hitungSisaTemplate(deadlineTemplate) {
    let sisa;
    if (deadlineTemplate >= 10) {
      sisa = deadlineTemplate - 10;
    } else {
      sisa = deadlineTemplate - 0;
    }
    return sisa;
  }

  hitungSisaCustom(deadlineCustom) {
    let sisa;
    if (deadlineCustom >= 17) {
      sisa = deadlineCustom - 17;
    } else if (deadlineCustom >= 10) {
      sisa = deadlineCustom - 10;
    } else {
      sisa = deadlineCustom - 0;
    }
    return sisa;
  }

  getNotifTemplate(deadlineTemplate, status, name) {
    let intervalID;
    if (status == "First Payment" && deadlineTemplate <= 10) {
      document.onload = setTimeout(function() {
        alert(`Hari ini deadline Preview Design untuk ${name}`);
      }, 2000);
      intervalID = setInterval(function() {
        alert(`Hari ini deadline Preview Design untuk ${name}`);
      }, 3600000);
    } else if (deadlineTemplate == 10 && status == "Naik Cetak") {
      intervalID = null;
    } else if (
      this.hitungSisaTemplate(deadlineTemplate) == 0 &&
      status == "First Payment"
    ) {
      document.onload = setTimeout(function() {
        alert(`Hari ini deadline Preview Design untuk ${name}`);
      }, 2000);
      intervalID = setInterval(function() {
        alert(`Hari ini deadline Preview Design untuk ${name}`);
      }, 3600000);
    } else if (deadlineTemplate <= 0 && status == "Preview Design") {
      document.onload = setTimeout(function() {
        alert(`Hari ini deadline Naik Cetak untuk ${name}`);
      }, 2000);
      intervalID = setInterval(function() {
        alert(`Hari ini deadline Naik Cetak untuk ${name}`);
      }, 3600000);
    }
  }

  getNotifCustom(deadlineCustom, status, name) {
    let intervalID;
    if (status == "First Payment" && deadlineCustom <= 24) {
      document.onload = setTimeout(function() {
        alert(`Hari ini deadline Preview Design untuk ${name}`);
      }, 2000);
      intervalID = setInterval(function() {
        alert(`Hari ini deadline Preview Design untuk ${name}`);
      }, 3600000);
    } else if (status == "Preview Design" && deadlineCustom <= 10) {
      document.onload = setTimeout(function() {
        alert(`Hari ini deadline Design Selesai untuk ${name}`);
      }, 2000);
      intervalID = setInterval(function() {
        alert(`Hari ini deadline Design Selesai untuk ${name}`);
      }, 3600000);
    } else if (status == "Design Selesai" && deadlineCustom <= 0) {
      document.onload = setTimeout(function() {
        alert(`Hari ini deadline Naik Cetak untuk ${name}`);
      }, 2000);
      intervalID = setInterval(function() {
        alert(`Hari ini deadline Naik Cetak untuk ${name}`);
      }, 3600000);
    }
  }

  render() {
    const { users, loading } = this.props.user;
    let no = 1;

    if (loading) {
      return (
        <div className="container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a disabled className="nav-link text-primary">
                Daftar Order Baru
              </a>
            </li>
            <li className="nav-item">
              <a disabled className="nav-link active">
                Order Kamu
              </a>
            </li>
            <li className="nav-item">
              <a disabled className="nav-link text-primary">
                Dalam Proses Cetak
              </a>
            </li>
          </ul>
          <div className="text-center">
            <h4 style={{ margin: "auto", display: "block" }}>
              Harap tunggu sebentar..
            </h4>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <Link
                to={`/all/${this.props.match.params.id}`}
                className="nav-link "
              >
                Daftar Order Baru
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to={`/list/${this.props.match.params.id}`}
                className="nav-link active"
              >
                Order Kamu
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/naik-cetak/${this.props.match.params.id}`}
                className="nav-link"
              >
                Dalam Proses Cetak
              </Link>
            </li>
          </ul>
          <div className="card pb-3 mt-3 shadow">
            <table className="table">
              <tbody>
                <tr className="bg-secondary text-white">
                  <th>No</th>
                  <th>Nama Customer</th>
                  <th>Jenis Pesanan</th>
                  {/* <th>Status</th> */}
                  <th>Deadline</th>
                  <th></th>
                </tr>
                {users.map(user => {
                  let now = new Date();
                  now = moment(now).format("DD/MM/YYYY");

                  let deadline = new Date(user.idOrder.tanggalDP);
                  deadline = moment(deadline).format("DD/MM/YYYY");

                  let a = moment(now, "DD/MM/YYYY");
                  let b = moment(deadline, "DD/MM/YYYY");
                  let diffDays = b.diff(a, "days");

                  let deadlineTemplate = diffDays + 15;
                  let deadlineCustom = diffDays + 31;
                  return (
                    <tr
                      key={user._id}
                      className={
                        user.idOrder.design_type == "custom"
                          ? "background-custom"
                          : ""
                      }
                    >
                      <td className="text-center">
                        {(no, no++)}
                        {/* tanggal DP :
                      {
                        <Moment format="DD MMMM YYYY">
                          {user.idOrder.tanggalDP}
                        </Moment>
                      }{" "}
                      <br />
                      deadline Template = {deadlineTemplate} <br />
                      deadline Custom = {deadlineCustom} <br />
                      sisa ke status berikutnya ={" "}
                      {this.hitungSisaTemplate(deadlineTemplate)} <br /> */}
                      </td>
                      <td>{user.name}</td>
                      <td className="text-capitalize">
                        {user.idOrder.design_type}
                      </td>
                      {/* <td>{user.idOrder.status}</td> */}
                      <td>
                        {user.idOrder.design_type == "template"
                          ? this.renderStatusTemplate(
                              deadlineTemplate,
                              user.idOrder.status
                            )
                          : this.renderStatusCustom(
                              deadlineCustom,
                              user.idOrder.status
                            )}
                      </td>
                      {/* {console.log(this.hitungSisaTemplate(deadline))} */}
                      <td>
                        <Link
                          to={`/users/${user._id}`}
                          className="btn btn-outline-dark shadow py-0"
                        >
                          Lihat Pesanan
                        </Link>
                      </td>
                      <td className="d-none">
                        {/* {user.idOrder.design_type == "template"
                          ? this.getNotifTemplate(
                              deadlineTemplate,
                              user.idOrder.status,
                              user.name
                            )
                          : this.getNotifCustom(
                              deadlineCustom,
                              user.idOrder.status,
                              user.name
                            )} */}
                        {/* }} */}
                        {/* {this.hitungSisaTemplate(deadline) == 4
                        ? (alert(`Deadline ${user.name}`),
                          (intervalID = setInterval(function() {
                            alert(`Deadline ${user.name}`);
                          }, 3600000)))
                        : ""} */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              {/* <tr style={{ backgroundColor: "#F5FAFF" }}>
                <td className="text-center align-middle">
                  <h4 className="align-middle mb-0" style={{ color: "red" }}>
                    <i className="fas fa-exclamation-circle "></i>
                  </h4>
                </td>
                <td>Rio Dewanto</td>
                <td>Custom</td>
                <td>
                  <p
                    className="btn  p-0 px-3 mb-0 font-weight-bold"
                    style={{ backgroundColor: "lime" }}
                  >
                    15 hari lagi
                  </p>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-outline-dark shadow py-0"
                  >
                    Lihat Pesanan
                  </button>
                </td>
              </tr> */}
            </table>
          </div>
        </div>
      );
    }
  }
}

ListUsersByDesigner.propTypes = {
  getUsersByDesigner: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default connect(mapStateToProps, { getUsersByDesigner })(
  ListUsersByDesigner
);
